import { usePrompt } from "@/common/hooks/usePrompt";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";
import { UpdateRouteAgreementMutation } from "@/gql/graphql";
import { Box, Row, Space, useDebounce } from "@stenajs-webui/core";
import {
  InputSpinner,
  PrimaryButton,
  useResultListBannerState,
} from "@stenajs-webui/elements";
import { uniq } from "lodash";
import * as React from "react";
import { useMemo, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { LoadingModalWithLock } from "../../../../../common/components/loading-modals/LoadingModalWithLock";
import { useFadeState } from "../../../../../common/hooks/UseFadedState";
import { useAllFormulaTypes } from "../../../../../common/query-hooks/UseAllFormulaTypes";
import { StoreState } from "../../../../../config/redux/RootReducer";
import { updateCustomerRouteStatisticsActualOutcome } from "../../../../customer-route-statistic-boxes/thunks/UpdateCustomerRouteStatisticsActualOutcome";
import { getRateSheetsWithChanges } from "../../../customer-route/transformers/ModifiedToInputTransformer";
import { transformToUpdateCustomerRouteStatisticsActualOutcomeInput } from "../../../customer-route/transformers/TransformToUpdateCustomerRouteStatisticsActualOutcomeInput";
import { rateSheetTableSelectors } from "../../../rate-sheet/selectors";
import { useRecalculationOfPriceRowsIsRequiredBeforeSave } from "../../../stats-calculation/recalc-stats-for-single-route-agreement-price/hooks/UseRecalculationOfPriceRowsIsRequiredBeforeSave";
import { EditRouteAgreementFormModel } from "../../common/components/models/EditRouteAgreementFormModel";
import { articleFormActions } from "../features/articles/actions";
import { SortArticleBy } from "../features/articles/components/ArticleSortBySelect";
import { ArticleItemFormModel } from "../features/articles/models/ArticlesFormModel";
import { articleFormSelectors } from "../features/articles/selectors";
import { ConditionsFormModel } from "../features/conditions/ConditionsFormModel";
import { conditionsFormRedux } from "../features/conditions/ConditionsFormRedux";
import { MatrixFormModel } from "../features/matrix/MatrixFormModel";
import { matrixFormRedux } from "../features/matrix/MatrixFormRedux";
import { NotesFormModel } from "../features/notes/NotesFormModel";
import { notesFormRedux } from "../features/notes/NotesFormRedux";
import { routesFormRedux } from "../features/routes/RoutesFormRedux";
import { useUpdateMultiLegRouteAgreementMutation } from "../hooks/UseUpdateMultiLegRouteAgreementMutation";
import { useUpdateRouteAgreementMutation } from "../hooks/UseUpdateRouteAgreementMutation";
import { RouteAgreementFormModel } from "../models/RouteAgreementFormModel";
import { routeAgreementOriginalSelectors } from "../redux";
import { populateEditRouteAgreementState } from "../thunks/RouteAgreementDetailsStatePopulator";
import { createUpdateFormInput } from "../transformer/FormToInputTransformer";
import { formModelsAreEqual } from "../utils/FormDiffCalculator";
import { RouteAgreementDiscardChangesButton } from "./RouteAgreementDiscardChangesButton";

// Checks each price object for empty string values and returns the field names that are empty
const findEmptyValueKeys = (prices: any[], usesPercent: boolean): string[] => {
  return prices.reduce((acc: string[], obj) => {
    const emptyKeys = Object.entries(obj)
      .filter(([key, value]) => {
        // Skip price field if using percentage-based pricing
        if (usesPercent && key === "price") return false;
        // Skip percent field if using fixed pricing
        if (!usesPercent && key === "percent") return false;
        // Only check for empty string values
        return value === "";
      })
      .map(([key, _]) => key);

    // Combine with accumulated keys, removing duplicates
    return [...new Set([...acc, ...emptyKeys])];
  }, []);
};

// Creates a formatted string listing all empty required fields across all articles
const getMissingValueKeysArray = (
  articles: Record<string, ArticleItemFormModel>
) => {
  // Find all empty keys across all articles
  const allEmptyKeys = Object.values(articles).reduce(
    (acc: string[], article) => {
      const emptyKeys = findEmptyValueKeys(article.prices, article.usesPercent);
      return [...new Set([...acc, ...emptyKeys])];
    },
    []
  );

  // Formats a key from camelCase to Title Case with spaces
  const transformEmptyKeysString = (str: string): string => {
    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .toLowerCase()
      .replace(/^(\w)/, (char) => char.toUpperCase());
  };

  // Handle cases based on number of empty keys
  if (allEmptyKeys.length === 0) return "";
  if (allEmptyKeys.length === 1)
    return transformEmptyKeysString(allEmptyKeys[0]);

  // Format list with commas and 'and' for multiple items
  const lastItem = allEmptyKeys.pop();
  return `${allEmptyKeys
    .map(transformEmptyKeysString)
    .join(", ")} and ${transformEmptyKeysString(lastItem ?? "")}`;
};

interface Props {
  routeAgreementId: string;
  editableRouteAgreement: boolean;
  isMultiLeg?: boolean;
  rowVersion: string;
  bannerHook: ReturnType<typeof useResultListBannerState>;
}

interface ArticlesFormModelWithoutExpandedArticleState {
  articles: Record<string, ArticleItemFormModel>;
  articleIds: Array<string>;
  expandedArticleState?: Record<string, boolean>;
  sortedBy?: SortArticleBy;
}
export interface FormModelCopyState {
  articles: ArticlesFormModelWithoutExpandedArticleState;
  conditions: ConditionsFormModel;
  routes: EditRouteAgreementFormModel;
  notes: NotesFormModel;
  matrix: MatrixFormModel;
}

export const RouteAgreementSaveButton: React.FC<Props> = ({
  routeAgreementId,
  rowVersion,
  isMultiLeg,
  bannerHook: {
    setBannerState,
    clearBannerResult,
    setBannerResultWithErrors,
    setBannerResultWithTexts,
  },
  editableRouteAgreement,
}) => {
  const dispatch = useDispatch();

  const { updateRouteAgreement } = useUpdateRouteAgreementMutation();
  const { updateMultiLegRouteAgreement } =
    useUpdateMultiLegRouteAgreementMutation();

  const { recalculationOfPriceRowsIsRequiredBeforeSave } =
    useRecalculationOfPriceRowsIsRequiredBeforeSave(
      routeAgreementId,
      editableRouteAgreement
    );
  const originalFormModel = useSelector(
    routeAgreementOriginalSelectors.getEntity
  );

  const {
    formulaTypes,
    loading: loadingFormulaTypes,
    error: errorFormulaTypes,
  } = useAllFormulaTypes();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useFadeState(false, 2000);
  const routesFormModel = useSelector(routesFormRedux.selectors.getEntity);
  const conditionsFormModel = useSelector(
    conditionsFormRedux.selectors.getEntity
  );
  const notesFormModel = useSelector(notesFormRedux.selectors.getEntity);
  const articlesFormModel = useSelector(articleFormSelectors.getState);
  const matrixFormModel = useSelector(matrixFormRedux.selectors.getEntity);
  const rateSheetState = useSelector(rateSheetTableSelectors.getTableState);

  const store = useStore<StoreState>();

  const formModel = useMemo<RouteAgreementFormModel>(() => {
    return {
      routes: routesFormModel,
      conditions: conditionsFormModel,
      articles: articlesFormModel,
      notes: notesFormModel,
      matrix: matrixFormModel,
    };
  }, [
    routesFormModel,
    conditionsFormModel,
    notesFormModel,
    articlesFormModel,
    matrixFormModel,
  ]);

  const missingValueKeysErrorItems = Object.values(formModel.articles.articles)
    .filter(
      (article) =>
        findEmptyValueKeys(article.prices, article.usesPercent).length > 0
    )
    .map((article) => ({
      text: `${article.articleTypeId}: The field ${findEmptyValueKeys(
        article.prices,
        article.usesPercent
      )
        .map((key) =>
          key
            .replace(/([a-z])([A-Z])/g, "$1 $2")
            .toLowerCase()
            .replace(/^(\w)/, (char) => char.toUpperCase())
        )
        .join(" and ")} can't be empty`,
    }));

  const debouncedFormModel = useDebounce(formModel, 1000);

  const hasUnsavedRateSheetChanges = useMemo<boolean>(() => {
    const rateSheetRowState = rateSheetState[routeAgreementId];
    if (!rateSheetRowState) {
      return false;
    }
    return rateSheetRowState.rows.some((r) => r.modified);
  }, [rateSheetState, routeAgreementId]);
  const hasChanges = useMemo(() => {
    return (
      !formModelsAreEqual(debouncedFormModel, originalFormModel) ||
      hasUnsavedRateSheetChanges
    );
  }, [debouncedFormModel, hasUnsavedRateSheetChanges, originalFormModel]);

  const canSave = useMemo(
    () =>
      isMultiLeg
        ? hasChanges && !loadingFormulaTypes
        : !recalculationOfPriceRowsIsRequiredBeforeSave &&
          hasChanges &&
          !loadingFormulaTypes,
    [
      recalculationOfPriceRowsIsRequiredBeforeSave,
      hasChanges,
      isMultiLeg,
      loadingFormulaTypes,
    ]
  );

  const submitHandler = async () => {
    if (getMissingValueKeysArray(formModel.articles.articles).length) {
      setBannerState({
        headerText: "Could not save route agreement",
        items: missingValueKeysErrorItems,
      });
      Object.entries(formModel.articles.articles).forEach(
        ([articleId, article]) => {
          const emptyFields = findEmptyValueKeys(
            article.prices,
            article.usesPercent
          );
          if (emptyFields.length > 0) {
            dispatch(
              articleFormActions.setInputFieldErrors(articleId, emptyFields)
            );
          }
        }
      );
      return;
    }
    if (errorFormulaTypes) {
      setBannerState({
        headerText: "Could not fetch formulas.",
      });
      setBannerResultWithErrors([errorFormulaTypes]);
      return;
    }
    setLoading(true);
    clearBannerResult();
    try {
      const rateSheetTableRowState = rateSheetState[routeAgreementId].rows;
      const input = createUpdateFormInput(
        routeAgreementId,
        rowVersion,
        formModel,
        originalFormModel,
        rateSheetTableRowState
          ? getRateSheetsWithChanges(rateSheetTableRowState!)
          : [],
        formulaTypes ?? []
      );

      let updateRouteAgreementResult:
        | UpdateRouteAgreementMutation["productPrice"]["routeAgreement"]["updateRouteAgreement"]
        | undefined = undefined;

      if (isMultiLeg) {
        const response = await updateMultiLegRouteAgreement(input);
        updateRouteAgreementResult =
          response.data?.productPrice.routeAgreement
            .updateRouteAgreementConnectedToMultiLeg;
      } else {
        const response = await updateRouteAgreement(input);
        updateRouteAgreementResult =
          response.data?.productPrice.routeAgreement.updateRouteAgreement;

        await dispatch(
          updateCustomerRouteStatisticsActualOutcome(
            transformToUpdateCustomerRouteStatisticsActualOutcomeInput(
              store.getState().statBoxes
            )
          )
        );
      }

      if (!updateRouteAgreementResult) {
        return;
      }

      if ("errors" in updateRouteAgreementResult) {
        const errors = updateRouteAgreementResult.errors;
        if (errors && errors.length > 0) {
          setBannerState({
            headerText: "Could not save route agreement",
          });
          const distinctErrorMessages = uniq(
            errors.map((error) => error.message)
          );
          setBannerResultWithTexts(distinctErrorMessages);
        }
      } else {
        await dispatch(populateEditRouteAgreementState(routeAgreementId, true));
        dispatch(articleFormActions.triggerArticleSorting());
        setSuccess(true);
      }
    } catch (e) {
      setBannerState({
        headerText: "Could not save route agreement",
      });
      setBannerResultWithErrors([e]);
    }
    setLoading(false);
  };

  usePrompt({
    shouldBlock: hasChanges,
    message: "You have unsaved changes, would you like to leave the page?",
  });

  return (
    <Row alignItems={"center"}>
      {loading && <LoadingModalWithLock label={"Saving route agreement..."} />}

      <Box width={"24px"}>
        {debouncedFormModel !== formModel && <InputSpinner />}
      </Box>

      <Space />
      <RouteAgreementDiscardChangesButton
        disabled={!hasChanges}
        onDiscardChanges={clearBannerResult}
        dataTestid={testIdConstants.routeAgreementDiscardButton}
      />
      <Space />
      <PrimaryButton
        label={"Save"}
        onClick={submitHandler}
        loading={loading}
        disabled={!canSave}
        success={success}
        data-testid={testIdConstants.routeAgreementSaveButton}
      />
    </Row>
  );
};
