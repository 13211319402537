export const testIdConstants = {
  globalCustomerSearch: "global-customer-search",
  customerNavigateToHeadAgreementButton:
    "customer-navigate-to-head-agreement-button",
  loginButton: "login-button",
  logOutButton: "log-out-button",
  userMenuButton: "user-menu-button",
  navBar: "nav-bar",
  menuLinkRouteTargets: "menu-link-route-targets",
  menuLinkNegotiationPlan: "menu-link-negotiation-plan",
  menuLinkStatistics: "menu-link-statistics",
  menuLinkPriceCalculator: "menu-link-price-calculator",
  menuLinkNSLHRoutePrices: "menu-link-nslh-route-prices",
  menuLinkNSLHHandleBookings: "menu-link-nslh-handle-bookings",
  menuLinkNSLHFollowUp: "menu-link-nslh-follow-up",
  menuLinkAdminGroupFreightTargets: "menu-link-admin-group-freight-targets",
  raiseTypeSelect: "raise-type-select",
  numberDecimalsSelect: "number-decimals-select",
  raiseByTypeSelect: "raise-by-type-select",
  filterSearchButton: "filter-search-button",
  routeFilterSection: "route-filter-section",
  routeFilterSectionSeaAreaCollapsible:
    "route-filter-section-sea-area-collapsible",
  routeFilterSectionCheckboxList: "route-filter-section-checkbox-list",
  salesRegionWithCountriesFilterSection:
    "sales-region-with-countries-filter-section",
  salesRegionWithCountriesFilterSectionCollapsible:
    "sales-region-with-countries-filter-section-collapsible",
  salesRegionWithCountriesFilterSectionCheckboxList:
    "sales-region-with-countries-filter-section-checkbox-list",
  statisticsTotalCustomersResult: "statistics-total-customers-result",
  routeTargetsOverviewNegotiationYear:
    "route-targets-overview-negotiation-year",
  negotiationPlanTotalCustomersResult:
    "negotiation-plan-total-customers-result",
  priceCalculatorSearchFieldHeader: "price-calculator-search-field-header",
  priceCalculatorCustomerOrBookingBox:
    "price-calculator-customer-or-booking-box",
  priceCalculatorCustomerSearchField: "price-calculator-customer-search-field",
  priceCalculatorBookingSearchField: "price-calculator-booking-search-field",
  priceCalculatorConsignmentVersionBox:
    "price-calculator-consignment-version-box",
  priceCalculatorMovementTypeSelection:
    "price-calculator-movement-type-selection",
  priceCalculatorSectionalRouteSelect:
    "price-calculator-sectional-route-select",
  priceCalculatorSectionalTimetableSelect:
    "price-calculator-sectional-timetable-select",
  priceCalculatorSectionalSailingCategorySelect:
    "price-calculator-sectional-sailing-category-select",
  priceCalculatorSectionalDepartureTimeInput:
    "price-calculator-sectional-departure-time-input",
  priceCalculatorCalculatePriceButton:
    "price-calculator-calculate-price-button",
  priceCalculatorResultTripInformation:
    "price-calculator-result-trip-information",
  priceCalculatorResultVehicleType: "price-calculator-result-vehicle-type",
  priceCalculatorResultPriceTripInformation:
    "price-calculator-result-price-trip-information",
  priceCalculatorResultPriceVehicleInformation:
    "price-calculator-result-price-vehicle-information",
  priceCalculatorResultPriceLegBox: "price-calculator-result-price-leg-box",
  priceCalculatorResultPriceTable: "price-calculator-result-price-table",
  priceCalculatorCalculateChangeCustomerButton:
    "price-calculator-change-customer-button",
  headerAgreementActionsMenu: "header-agreement-actions-menu",
  headerAgreementActionsMenuCopyAgreement:
    "header-agreement-actions-menu-copy-agreement",
  headerAgreementActionsMenuCopyToOtherCustomer:
    "header-agreement-actions-menu-copy-to-other-customer",
  headerAgreementActionsMenuEditAgreement:
    "header-agreement-actions-menu-edit-agreement",
  headerAgreementActionsMenuNewAgreement:
    "header-agreement-actions-menu-new-agreement",
  headerAgreementActionsMenuAgreementArticles:
    "header-agreement-actions-menu-agreement-articles",
  headerAgreementActionsMenuCreateOffer:
    "header-agreement-actions-menu-create-offer",
  headerAgreementActionsMenuCreateConfirmation:
    "header-agreement-actions-menu-create-confirmation",
  headerAgreementActionsMenuSetToPending:
    "header-agreement-actions-menu-set-to-pending",
  headerAgreementActionsMenuActivate: "header-agreement-actions-menu-activate",
  headerAgreementActionsMenuSetToVoid:
    "header-agreement-actions-menu-set-to-void",
  headerAgreementActionsMenuDelete: "header-agreement-actions-menu-delete",
  headerAgreementEditRatesButton: "header-agreement-edit-rates-button",
  headerAgreementDocumentsButton: "header-agreement-documents-button",
  bulkEditRatesFilterButton: "bulk-edit-rates-filter-button",
  bulkEditRatesSaveButton: "bulk-edit-rates-save-button",
  bulkEditRatesRaiseRateByInput: "bulk-edit-rates-raise-rate-by-input",
  bulkEditRatesActionsPanelApplyButton:
    "bulk-edit-rates-actions-panel-apply-button",
  nameInput: "name-input",
  validityInput: "validity-input",
  createHeaderAgreementCreateButton: "create-header-agreement-create-button",
  createHeaderAgreementCancelButton: "create-header-agreement-cancel-button",
  agreementReportSpecificationsArea: "agreement-report-specification-area",
  agreementReportConditionArea: "agreement-report-condition-area",
  agreementReportLastPageArea: "agreement-report-last-page-area",
  InternalNotes: "internal-notes",
  deleteHeaderAgreementModalYesButton:
    "delete-header-agreement-modal-yes-button",
  editHeaderAgreementSaveAgreementButton:
    "edit-header-agreement-save-agreement-button",
  editHeaderAgreementCancelButton: "edit-header-agreement-cancel-button",
  newRouteAgreementButton: "new-route-agreement-button",
  createRouteAgreementModalCancelButton:
    "create-route-agreement-modal-cancel-button",
  createRouteAgreementModalCreateAgreementButton:
    "create-route-agreement-modal-create-agreement-button",
  errorMessageText: "error-message-text",
  createRouteAgreementSailingCategoryPeak:
    "create-route-agreement-sailing-category-peak",
  createRouteAgreementSailingCategoryOffpeak:
    "create-route-agreement-sailing-category-offpeak",
  createRouteAgreementSailingCategoryShoulder:
    "create-route-agreement-sailing-category-shoulder",
  createRouteAgreementCurrencySelector:
    "create-route-agreement-currency-selector",
  createRouteAgreementSelectRoute: "create-route-agreement-select-route",
  createRouteAgreementNameTextBox: "create-route-agreement-name-text-box",
  breadcrumbCustomer: "breadcrumb-customer",
  routeAgreementRatesTab: "route-agreement-rates-tab",
  routeAgreementRoutesTab: "route-agreement-routes-tab",
  createRouteRatesAddVehicleType: "create-route-rates-add-vehicle-type",
  createRouteRatesAddVehicleAddButton:
    "create-route-rates-add-vehicle-add-button",
  createRouteRatesSelectVehicleType:
    "create-route-rates-select-vehicle-add-button",
  createRouteRatesRecalculateStatistics:
    "create-route-rates-recalculate-statistics",
  routeAgreementSaveButton: "route-agreement-save-button",
  createRouteRatesAddVehicleTypeSelect:
    "create-route-rates-add-vehicle-type-select",
  createRouteRatesAddVehicleLengthFrom: "create-route-rates-length-from",
  createRouteRatesAddVehicleLengthTo: "create-route-rates-length-to",
  createRouteRatesAddVehicleWeightFrom: "create-route-rates-weight-from",
  createRouteRatesAddVehicleWeightTo: "create-route-rates-weight-to",
  createRouteRatesErrorBanner: "create-route-rates-error-banner",
  routeRatesToAgreementButton: "route-rates-to-agreement-button",
  routeAgreementDiscardButton: "route-agreement-discard-button",
  groupFreightTargetsSaveButton: "group-freight-targets-save-button",
  groupFreightTargetsDiscardButton: "group-freight-targets-discard-button",
  nSLHRoutePricesDiscardButton: "n-s-l-h-route-prices-discard-button",
  nSLHRoutePricesSaveButton: "n-s-l-h-route-prices-save-button",
  nSLHFollowUpOverviewInfoBox: "n-s-l-h-follow-up-overview-info-box",
  routePriceAddNewValidFromDateYearSelect:
    "route-price-add-new-valid-from-date-year-select",
  routePriceIncludePriceRadioButton: "route-price-include-price-radio-button",
  routePriceDoNotIncludePriceRadioButton:
    "route-price-do-not-include-price-radio-button",
  routePriceAddNewValidFromDateConfirmButton:
    "route-price-add-new-valid-from-date-button-confirm",
  routePriceDeleteNewValidFromDateConfirmButton:
    "route-price-delete-new-valid-from-date-button-confirm",
  vehicleTypeFilterSection: "vehicle-type-filter-section",
  vehicleTypeFilterSectionTypeAllCheckbox:
    "vehicle-type-filter-section-type-all-checkbox",
  rebateFilterButton: "rebate-filter-button",
  nSLHHandleBookingsFilterButton: "n-s-l-h-handle-bookings-filter-button",
  nslhPageHeader: "NSLHHFollowUpHeader",
  searchInvoiceSpecificationsButton: "searchInvoiceSpecifications",
  noInvoicesBanner: "invoices_empty_list_banner",
  documentsViewTabs: "document-view-tabs",
  PriceCalculatorPriceDetailsButton: "price-calculator-price-details-button",
  PriceCalculatorAuditTrackButton: "price-calculator-audit-track-button",
};
