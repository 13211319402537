import { StoreState } from "../../../../../../config/redux/RootReducer";

export const articleFormSelectors = {
  getState: (state: StoreState) =>
    state.routeAgreementDetails.workspace.articles,
  getSpecificArticleState: (state: StoreState, articleId: string) =>
    state.routeAgreementDetails.workspace.articles.articles[articleId],
  getSpecificArticleExpandedState: (state: StoreState, articleId: string) =>
    state.routeAgreementDetails.workspace.articles.expandedArticleState[
      articleId
    ],
  getSortedByState: (state: StoreState) =>
    state.routeAgreementDetails.workspace.articles.sortedBy,
  getInputFieldErrors: (state: StoreState) =>
    state.routeAgreementDetails.workspace.articles.inputErrorFields,
};
