import {
  ArticleItemFormModel,
  ArticlePriceFormModel,
  ArticlePriceValidityFormModel,
} from "./models/ArticlesFormModel";
import { parseISO } from "date-fns";
import { ArticlePriceItemRow } from "./components/ArticlePriceItemsTable";

const sortPricesBasedOnVehicleType = (
  a: ArticlePriceFormModel,
  b: ArticlePriceFormModel
) => {
  if (a.vehicleTypeId == null) {
    return -1;
  }
  if (b.vehicleTypeId == null) {
    return 1;
  }
  if (a.vehicleTypeId < b.vehicleTypeId) {
    return -1;
  }
  if (a.vehicleTypeId > b.vehicleTypeId) {
    return 1;
  }
  return 0;
};
const sortValiditiesByValidFromDate = (
  a: ArticlePriceValidityFormModel,
  b: ArticlePriceValidityFormModel
) => {
  if (a.validFromDate === null) {
    return 1;
  }
  if (b.validFromDate === null) {
    return -1;
  }
  if (a.validFromDate < b.validFromDate) {
    return -1;
  }
  if (a.validFromDate > b.validFromDate) {
    return 1;
  }
  return 0;
};
export const transformArticleToArticlePriceItemRows = (
  article: ArticleItemFormModel
) => {
  const articlePriceItemRows: ArticlePriceItemRow[] = [];

  const prices = Object.values(article.prices).sort(
    sortPricesBasedOnVehicleType
  );

  for (const price of prices) {
    const validitiesForPrice = article.validities.filter(
      (validity) => validity.agrRouteArticlePriceId === price.id
    );

    const validitiesForPriceWithDates =
      validitiesForPrice.map<ArticlePriceValidityFormModel>((validity) => {
        return {
          ...validity,
          validFromDate:
            validity.validFrom !== null ? parseISO(validity.validFrom) : null,
        };
      });
    const validitiesForPriceWithDatesOrdered = validitiesForPriceWithDates.sort(
      sortValiditiesByValidFromDate
    );

    articlePriceItemRows.push({
      pricesDeleted: price.deleted,
      priceId: price.id,
      priceValidityId: null,
      percentage: price.percent,
      vehicleTypeId: price.vehicleTypeId ?? null,
      price: price.price,
      lengthFrom: price.lengthFrom,
      lengthTo: price.lengthTo,
      weightTo: price.weightTo,
      weightFrom: price.weightFrom,
      validFromDate: null,
    });

    validitiesForPriceWithDatesOrdered.forEach((validity) => {
      articlePriceItemRows.push({
        pricesDeleted: price.deleted,
        priceId: price.id,
        priceValidityId: validity.id,
        percentage: validity.percentage ?? "",
        vehicleTypeId: price.vehicleTypeId ?? null,
        price: validity.price,
        lengthFrom: price.lengthFrom,
        lengthTo: price.lengthTo,
        weightTo: price.weightTo,
        weightFrom: price.weightFrom,
        validFromDate: validity.validFromDate,
      });
    });
  }
  return articlePriceItemRows;
};
