import { Column, Row, Space, Text, Txt } from "@stenajs-webui/core";
import { FlatButton, stenaPlusCircle } from "@stenajs-webui/elements";
import * as React from "react";
import { ArticleItemFormModel } from "../models/ArticlesFormModel";
import { ArticlePriceItem } from "./ArticlePriceItem";
import { cssColor } from "@stenajs-webui/theme";
import { transformArticleToArticlePriceItemRows } from "../TransformArticleToArticlePriceItemRows";

interface Props {
  showPrice?: boolean;
  showPercent?: boolean;
  article: ArticleItemFormModel;
  addNewPriceHandler: () => void;
}

// ROW ITEMS
const vehicleTypeWidth = 185;
const priceWidth = 105;
const percentWidth = 105;
const lengthFromWidth = 95;
const lengthToWidth = 95;
const weightFromWidth = 95;
const weightToWidth = 95;
const validFromWidth = 140;

// ROW HEADER
const vehicleTypeHeaderWidth = 165;
const priceHeaderWidth = 95;
const percentHeaderWidth = 95;
const lengthFromHeaderWidth = 84;
const lengthToHeaderWidth = 84;
const weightFromHeaderWidth = 84;
const weightToHeaderWidth = 84;
const validFromHeaderWidth = 130;

export interface ArticlePriceItemRow {
  pricesDeleted: boolean;
  priceId: string;
  priceValidityId: string | null;
  vehicleTypeId: string | null;
  price: string;
  percentage: string;
  lengthFrom: string;
  lengthTo: string;
  weightFrom: string;
  weightTo: string;
  validFromDate: Date | null;
}

export const ArticlePriceItemsTable: React.FC<Props> = ({
  article,
  showPrice,
  showPercent,
  addNewPriceHandler,
}) => {
  const articlePriceItemRows = transformArticleToArticlePriceItemRows(article);
  return (
    <Column width={850}>
      <Row>
        <Space num={2} />
        <Row width={vehicleTypeHeaderWidth}>
          <Txt size="small">{"Vehicle type"} </Txt>
        </Row>
        {showPrice && (
          <Row width={priceHeaderWidth}>
            <Txt size="small">{"Price"}</Txt>
          </Row>
        )}
        {showPercent && (
          <Row width={percentHeaderWidth}>
            <Txt size="small">{"Percent"}</Txt>
          </Row>
        )}
        <Row width={lengthFromHeaderWidth}>
          <Txt size="small">{"Length from"}</Txt>
        </Row>
        <Row width={lengthToHeaderWidth}>
          <Txt size="small">{"Length to"} </Txt>
        </Row>
        <Row width={weightFromHeaderWidth}>
          <Txt size="small">{"Weight from"}</Txt>
        </Row>
        <Row width={weightToHeaderWidth}>
          <Txt size="small">{"Weight to"}</Txt>
        </Row>
        <Row width={validFromHeaderWidth}>
          <Txt size="small">{"Valid from"}</Txt>
        </Row>
        <Row width={"100px"}>
          <Txt title={""} />
        </Row>
        <Space num={3} />
      </Row>

      {article.includedInPrice && (
        <>
          <Space num={2} />
          <Row justifyContent={"center"}>
            <Text size="small" color={cssColor("--lhds-color-ui-500")}>
              Since this article is included in price you can't add vehicle
              prices.
            </Text>
          </Row>
        </>
      )}

      {!article.prices.length && !article.includedInPrice && (
        <>
          <Space num={2} />
          <Row justifyContent={"center"}>
            <Text size="small" color={cssColor("--lhds-color-ui-500")}>
              No vehicle types added.
            </Text>
          </Row>
        </>
      )}

      {!article.includedInPrice &&
        articlePriceItemRows
          .filter((a) => !a.pricesDeleted)
          .map((articlePriceRow, index) => (
            <ArticlePriceItem
              key={`${articlePriceRow.priceId}:${articlePriceRow.priceValidityId}:${index}`}
              articlePriceRow={articlePriceRow}
              articleId={article.id}
              showPrice={showPrice}
              showPercent={showPercent}
              vehicleTypeWidth={vehicleTypeWidth}
              priceWidth={priceWidth}
              percentWidth={percentWidth}
              lengthFromWidth={lengthFromWidth}
              lengthToWidth={lengthToWidth}
              weightFromWidth={weightFromWidth}
              weightToWidth={weightToWidth}
              validFromWidth={validFromWidth}
            />
          ))}
      {!article.includedInPrice && (
        <Row indent={2}>
          <FlatButton
            leftIcon={stenaPlusCircle}
            label={"Add new vehicle"}
            onClick={addNewPriceHandler}
          />
        </Row>
      )}
    </Column>
  );
};
