import { fromPairs } from "lodash";
import { uuid } from "../../../../../../common/uuid/UuidFactory";
import { ArticleFormAction } from "./actions";
import { SortArticleBy } from "./components/ArticleSortBySelect";
import { createEmptyArticlesPriceFormModel } from "./models/ArticlePriceFormModel";
import {
  ArticlesFormModel,
  createEmptyArticleItemFormModel,
  emptyArticlesFormModel,
  sortArticlesBy,
} from "./models/ArticlesFormModel";

export interface ArticlesFormState extends ArticlesFormModel {
  sortedBy: SortArticleBy;
}

export const emptyArticlesFormModelInitialState: ArticlesFormState = {
  ...emptyArticlesFormModel,
  sortedBy: SortArticleBy.CodeAsc,
};

export const articlesFormReducer = (
  state: ArticlesFormState = emptyArticlesFormModelInitialState,
  action: ArticleFormAction
): ArticlesFormState => {
  switch (action.type) {
    case "ARTICLE_FORM:SET_FORM_MODEL": {
      return {
        ...state,
        ...action.formModel,
        articleIds: sortArticlesBy(
          state.sortedBy,
          Object.values(action.formModel.articles)
        ).map((a) => a.id),
      };
    }

    case "ARTICLE_FORM:REMOVE_ARTICLE_TYPE": {
      const { articleId } = action;

      const expandedArticleStateCopy = { ...state.expandedArticleState };
      delete expandedArticleStateCopy[articleId];

      return {
        ...state,
        articles: {
          ...state.articles,
          [articleId]: { ...state.articles[articleId], deleted: true },
        },
        articleIds: state.articleIds.filter((id) => id !== articleId),
        expandedArticleState: expandedArticleStateCopy,
      };
    }

    case "ARTICLE_FORM:SET_ARTICLE_TYPES_KEEP_ARTICLES": {
      const { articles: newArticles } = action;
      const newArticleIds = newArticles.map((a) => a.id);

      const persistedAndDeletedArticles = Object.values(state.articles)
        .filter((a) => a.persisted && !newArticleIds.some((id) => id === a.id))
        .map((p) => ({
          ...p,
          deleted: true,
        }));

      const articlesToKeep = Object.values(state.articles).filter((a) =>
        newArticleIds.some((id) => id === a.id)
      );

      const articlesToAdd = newArticles
        .filter(
          (article) =>
            !Object.values(state.articles).some(
              (o) => o.id === article.id && !o.deleted
            )
        )
        .map(({ id, usesPercent }) => {
          const emptyArticle = createEmptyArticleItemFormModel(id);
          return {
            ...emptyArticle,
            usesPercent,
          };
        });

      return {
        ...state,
        articles: fromPairs(
          [
            ...articlesToAdd,
            ...articlesToKeep,
            ...persistedAndDeletedArticles,
          ].map((a) => [a.id, a])
        ),
        articleIds: [
          ...articlesToAdd.map((ata) => ata.id),
          ...state.articleIds,
        ],
        expandedArticleState: {
          ...state.expandedArticleState,
          ...fromPairs([...articlesToAdd].map((a) => [a.id, true])),
        },
      };
    }

    case "ARTICLE_FORM:SET_ARTICLE_FIELDS": {
      const { fields, articleId } = action;

      if (!state.articles[articleId]) {
        return state;
      }

      return {
        ...state,
        articles: {
          ...state.articles,
          [articleId]: { ...state.articles[articleId], ...fields },
        },
      };
    }

    case "ARTICLE_FORM:SET_ARTICLE_PRICE_FIELDS": {
      const { articlePriceId, articleId, fields } = action;
      return {
        ...state,
        articles: {
          ...state.articles,
          [articleId]: {
            ...state.articles[articleId],
            prices: state.articles[articleId].prices.map((price) =>
              price.id === articlePriceId ? { ...price, ...fields } : price
            ),
          },
        },
      };
    }

    case "ARTICLE_FORM:ADD_ARTICLE_PRICE": {
      const { articleId } = action;
      const newPriceId = uuid(); // Add this line to generate the new ID
      return {
        ...state,
        articles: {
          ...state.articles,
          [articleId]: {
            ...state.articles[articleId],
            prices: [
              createEmptyArticlesPriceFormModel(newPriceId),
              ...state.articles[articleId].prices,
            ],
          },
        },
      };
    }

    case "ARTICLE_FORM:SET_FORMULA_DATA_FIELDS": {
      const { articleId, fields } = action;
      return {
        ...state,
        articles: {
          ...state.articles,
          [articleId]: {
            ...state.articles[articleId],
            formulaData: {
              ...state.articles[articleId].formulaData,
              ...fields,
            },
          },
        },
      };
    }

    case "ARTICLE_FORM:TOGGLE_EXPANDED_CARD": {
      const { articleId } = action;
      return {
        ...state,
        expandedArticleState: {
          ...state.expandedArticleState,
          [articleId]: !state.expandedArticleState[articleId],
        },
      };
    }

    case "ARTICLE_FORM:TOGGLE_ALL_EXPANDED_CARD": {
      const AllCardsExpanded = Object.values(state.expandedArticleState).every(
        (val) => val
      );
      const currentExpandedArticleStateCopy = { ...state.expandedArticleState };

      Object.keys(currentExpandedArticleStateCopy).forEach(
        (v) => (currentExpandedArticleStateCopy[v] = !AllCardsExpanded)
      );

      return {
        ...state,
        expandedArticleState: {
          ...currentExpandedArticleStateCopy,
        },
      };
    }

    case "ARTICLE_FORM:SORT_ARTICLES_BY": {
      const { sortBy } = action;

      return {
        ...state,
        articleIds: sortArticlesBy(sortBy, Object.values(state.articles)).map(
          (a) => a.id
        ),
        sortedBy: sortBy,
      };
    }

    case "ARTICLE_FORM:UPDATE_SORT_ARTICLES_BY": {
      return {
        ...state,
        articleIds: sortArticlesBy(
          state.sortedBy,
          Object.values(state.articles)
        ).map((a) => a.id),
      };
    }

    case "ARTICLE_FORM:COPY_ARTICLE_PRICE": {
      const { articlePriceId } = action;
      return {
        ...state,
        articles: fromPairs(
          Object.values(state.articles)
            .map((a) => {
              const priceToCopy = a.prices.find((p) => p.id === articlePriceId);
              return priceToCopy
                ? {
                    ...a,
                    prices: [
                      { ...priceToCopy, id: uuid(), persisted: false },
                      ...a.prices,
                    ],
                  }
                : a;
            })
            .map((a) => [a.id, a])
        ),
      };
    }

    case "ARTICLE_FORM:DELETE_ARTICLE_PRICE": {
      const { articlePriceId } = action;
      return {
        ...state,
        articles: fromPairs(
          Object.values(state.articles)
            .map((a) => {
              const priceToCopy = a.prices.find((p) => p.id === articlePriceId);
              return priceToCopy
                ? {
                    ...a,
                    prices: a.prices.map((p) =>
                      p.id === articlePriceId ? { ...p, deleted: true } : p
                    ),
                  }
                : a;
            })
            .map((a) => [a.id, a])
        ),
      };
    }

    case "ARTICLE_FORM:INPUT_ERROR": {
      const { articleId, fieldNames } = action;
      return {
        ...state,
        inputErrorFields: {
          ...state.inputErrorFields,
          [articleId]: fieldNames,
        },
      };
    }

    default:
      return state;
  }
};
