import {
  CardHeader,
  FlatButton,
  Tag,
  stenaAngleDown,
  stenaAngleRight,
  stenaTrash,
} from "@stenajs-webui/elements";
import * as React from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../../../../../config/redux/RootReducer";
import { articleFormActions } from "../actions";
import { ArticleItemFormModel } from "../models/ArticlesFormModel";
import { articleFormSelectors } from "../selectors";
import { SwitchWithLabel } from "@stenajs-webui/forms";
import { Box, Space, Txt } from "@stenajs-webui/core";
import { Tooltip } from "@stenajs-webui/tooltip";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { cssColor } from "@stenajs-webui/theme";
import { ArticleTypesMultiSelectQuery } from "@/gql/graphql";

interface Props {
  expanded: boolean;
  title: string;
  articleId: string;
  onClickExpand: () => void;
  onClickRemove: () => void;
  removeDisabled: boolean;
  articleType: ArticleTypesMultiSelectQuery["productPrice"]["articleType"]["all"]["0"];
}

export const ExpansionPaneHeader: React.FC<Props> = ({
  expanded,
  title,
  articleId,
  onClickRemove,
  onClickExpand,
  removeDisabled,
  articleType,
}) => {
  const article = useSelector((state: StoreState) =>
    articleFormSelectors.getSpecificArticleState(state, articleId)
  );
  const dispatch = useDispatch();
  const showFormulaTag = articleType.needsPricingData;
  const showPriceTag = article.prices.some((p) => p.price || p.percent);

  const setFields = useCallback(
    (fields: Partial<ArticleItemFormModel>) => {
      dispatch(articleFormActions.setArticleFields(articleId, fields));
    },
    [dispatch, articleId]
  );

  return (
    <CardHeader
      contentLeft={
        <>
          <FlatButton
            size="large"
            leftIcon={expanded ? stenaAngleDown : stenaAngleRight}
            onClick={onClickExpand}
          />
          <Box width={"50px"}>
            <Txt size="large" color={cssColor("--lhds-color-ui-900")}>
              {title}
            </Txt>
          </Box>
          <Space />

          <Tooltip
            label={
              "Included in price can only be used if no vehicles exist or have been added."
            }
            zIndex={ZIndex.lowest}
            variant="info"
            disabled={
              !(
                article.prices.length > 0 &&
                article.prices.some((p) => !p.deleted)
              )
            }
          >
            <SwitchWithLabel
              label="Included in Price"
              value={article.includedInPrice}
              onValueChange={(includedInPrice) => {
                setFields({ includedInPrice });
                if (includedInPrice) {
                  setFields({ formulaTypeId: undefined });
                }
              }}
              disabled={
                article.prices.length > 0 &&
                article.prices.some((p) => !p.deleted)
              }
            />
          </Tooltip>
          <Space />

          {showPriceTag && (
            <Tag size="medium" label="Prices" variant="passive" />
          )}
          {showFormulaTag && (
            <Tag size="medium" label="Formula" variant="passive" />
          )}
        </>
      }
      contentRight={
        <FlatButton
          leftIcon={stenaTrash}
          label={"Remove"}
          onClick={onClickRemove}
          disabled={removeDisabled}
        />
      }
    />
  );
};
